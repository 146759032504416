.footer {
  background: url("../images/footer-bg.jpg") center/cover no-repeat;

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 -15px;
    padding: 20px 0;
  }

  &__col {
    padding: 0 15px;

    &:not(:last-child) {
      border-right: 1px solid $white;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 15px;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $white;
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 14px 0;

    a {
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      color: $white;

      &:hover {
        color: $white;
        text-decoration: none;
      }

      &:not(:last-child) {
        margin-right: 60px;
      }
    }
  }

  &__statistic {
    color: $white;
  }

  &__copyright {
    color: $white;
    text-align: center;
    background-color: #0088bf;
    padding: 20px 0;
  }

  @media (max-width: 991px) {
    &__grid {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }

    &__col {
      &:not(:last-child) {
        border-right: 0;
      }
    }

    &__nav {
      display: block;
    }

    &__menu {
      flex-wrap: wrap;
      justify-content: center;

      a {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }

    &__statistic {
      text-align: center;
      margin-bottom: 14px;
    }
  }
}

.f-contact {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  color: $white;
  font-size: 14px;
  line-height: 24px;

  &__item {
    position: relative;
    padding-left: 30px;

    .fa {
      position: absolute;
      top: 4px;
      left: 0;
    }

    a,
    a:hover {
      color: $white;
    }
  }
}
