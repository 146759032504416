.contact-page {
  padding: 30px 0 0;

  &__title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
    padding-bottom: 8px;
    border-bottom: 1px solid $border-color;
  }

  @media (max-width: 991px) {
    &__title {
      margin-bottom: 10px;
    }
  }
}

.contact-form {
  &__btn {
    @include btn("fill");
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 20px;
    line-height: 33px;
    border-radius: 4px;
    background: 0;
    color: #333;
    border: 1px solid #333;

    &:hover {
      color: $white;
      border-color: $primary;
    }

    &::before {
      background-color: $primary;
    }
  }

  input.form-control {
    padding: 8px 20px;
    height: 40px;
    border: 1px solid $border-color;

    &::placeholder {
      color: #999;
    }
  }

  textarea.form-control {
    padding: 8px 20px;
    border: 1px solid $border-color;

    &::placeholder {
      color: #999;
    }
  }

  @media (max-width: 767px) {
    .form-group {
      margin-bottom: 16px;
    }
  }
}

.contact-info {
  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__subtitle {
    margin-bottom: 24px;
  }

  &__item {
    align-items: center;

    & + & {
      margin-top: 20px;
    }

    a,
    a:hover {
      color: inherit;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 18px;
    color: $primary;
    font-size: 24px;
  }

  &__label {
    font-size: 18px;
    font-weight: 700;
  }
}

.contact-map {
  height: 300px;
}
