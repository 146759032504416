.about {
  &__title {
    font-size: 30px;
    font-weight: 700;
    color: $primary;
    margin-bottom: 16px;
  }
}

.about-card {
  height: 100%;
  border: 1px solid $border-color;
  padding: 50px 30px 40px 30px;

  &__title {
    font-size: 36px;
    margin-bottom: 30px;
    position: relative;
    color: $primary;

    &::before {
      content: "";
      height: 100%;
      width: 2px;
      background-color: $primary;
      position: absolute;
      top: 0;
      left: -31px;
    }
  }

  &__content {
    line-height: 1.2;
  }
}
