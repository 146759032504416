.news {
  padding: 20px;
  border: 1px solid $border-color;
  border-radius: 10px;

  &__frame {
    @include frame("rect", 64%);
    @include frame("zoom");
    overflow: hidden;
    width: 245px;
    border-radius: 10px;
    margin-right: 20px;
  }

  &__info {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid $border-color;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__desc {
    @include max-line(3);
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
  }

  &__link {
    color: $primary;
    text-transform: uppercase;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    padding: 16px;

    &__frame {
      flex: 0 0 100%;
      margin-right: 0;
      margin-bottom: 8px;
    }

    &__title {
      font-size: 15px;
    }
  }
}

.product {
  display: flex;
  flex-direction: column;
  height: 100%;
  @include shadow("");

  &__frame {
    @include frame("rect", 66.66%);
    @include frame("zoom");
  }

  &__body {
    display: flex;
    position: relative;

    &:hover {
      text-decoration: none;

      &::after {
        content: "\f105";
        font-family: fontawesome;
        display: flex;
        width: 40px;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: $white;
        background-color: $primary;
        font-size: 20px;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-right: 15px;
    flex: 1;
    padding: 10px 4px 10px 20px;
    font-size: 20px;
    font-weight: 700;
    color: $danger;

    img {
      margin-right: 16px;
    }
  }
}

.certificate-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;

  @media (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 575px) {
    grid-gap: 16px;
  }
}

.certificate {
  &__frame {
    background: linear-gradient(to right, #382113, #f9eda1, #ae7d2c);
    padding: 10px;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 75%;
      background-color: $white;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 80%;
      max-height: 80%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin: 12px 0 0;
  }

  @media (max-width: 575px) {
    &__frame {
      padding: 8px;
    }

    &__title {
      font-size: 13px;
      margin-top: 8px;
    }
  }
}

.product-2 {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 50px 40px;
  box-shadow: 0 1px 10px rgba(#000, 0.15);
  background-color: $white;
  transition: 0.3s;

  &__icon {
    display: flex;
    align-items: flex-end;
    color: $border-color;
    margin-bottom: 30px;

    &::after {
      content: "";
      display: block;
      flex: 1;
      border-bottom: 1px solid currentColor;
    }

    img {
      height: 100px;
    }
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    color: $primary;
    line-height: 1.2;
    margin-bottom: 30px;

    a:hover {
      color: $primary;
    }
  }

  &__frame {
    @include frame("rect", 56.25%);
    @include frame("zoom");
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    position: relative;
  }

  &__overlay {
    display: flex;
    flex-direction: column;
  }

  &__desc {
    @include max-line(6);
    color: $white;
    margin-bottom: 16px;
  }

  &__btn {
    display: inline-flex;
    font-size: 18px;
    text-transform: uppercase;
    align-items: center;
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    img {
      filter: brightness(0) invert(1);
    }
  }

  // hover

  &:hover {
    background: $primary;
  }

  &:hover &__icon {
    color: $white;

    img {
      filter: brightness(0) invert(1);
    }
  }

  &:hover &__title {
    color: $white;

    a:hover {
      color: $white;
    }
  }

  &:hover &__frame {
    display: none;
  }

  @media (max-width: 1700px) {
    padding: 30px 20px;

    &__icon {
      img {
        height: 90px;
      }
    }

    &__title {
      font-size: 24px;
    }

    &__btn {
      font-size: 16px;
    }
  }
}
