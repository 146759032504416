.partners {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;

  &__frame {
    border: 1px solid $border-color;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 90%;
      max-height: 90%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  @media (max-width: 991px) {
    grid-gap: 16px;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 991px) {
    grid-gap: 8px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.intro {
  padding: 50px 0;

  &__inner {
    background: url("../images/world-map-bg.png") center/contain no-repeat;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__content {
    margin-bottom: 20px;
  }

  &__btn {
    display: inline-flex;
    align-items: center;
    height: 45px;
    border: 1px solid $primary;
    padding: 0 34px;
    border-radius: 23px;
    font-size: 14px;
    text-transform: uppercase;
    transition: 0.4s;

    img {
      transition: 0.4s;
    }

    &:hover {
      background-color: $primary;
      color: $white;
      text-decoration: none;

      img {
        filter: brightness(0) invert(1);
      }
    }
  }

  @media (max-width: 991px) {
    &__btn {
      height: 35px;
      padding: 0 20px;
    }
  }
}

.counting {
  padding: 45px 0;
  background: url("../images/counting-bg.jpg") center/cover no-repeat;

  &__intro {
    font-size: 16px;
    font-weight: 700;
    color: $white;
    text-align: center;
    max-width: 850px;
    margin: 0 auto 30px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }

  &__number {
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    color: #f1c21e;
    line-height: 1.2;
    margin-bottom: 16px;
  }

  &__label {
    font-size: 20px;
    font-weight: 700;
    color: $white;
    margin: 0 0 8px;
    text-align: center;
  }

  &__desc {
    color: $white;
    text-align: center;
  }

  @media (max-width: 991px) {
    &__grid {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 767px) {
    padding: 30px 0;

    &__intro {
      font-size: 14px;
      margin-bottom: 20px;
    }

    &__grid {
      grid-gap: 24px 16px;
    }

    &__number {
      font-size: 30px;
      margin-bottom: 8px;
    }

    &__label {
      font-size: 16px;
    }

    &__desc {
      font-size: 12px;
    }
  }
}

.h-product {
  background-color: $light;
  position: relative;
  z-index: 2;
  padding: 40px 0;
  margin-bottom: 40px;

  &__content {
    width: 100%;
    background-color: $light;
    // transform: translateY(-50%);
    // position: absolute;
    // top: 50%;
    // left: 0;
    // z-index: -1;
    margin-bottom: 30px;
  }

  &__inner {
    // width: 30%;
    transition: 0.4s;
    text-align: center;
  }

  &__title {
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 24px;
  }

  &__desc {
    font-size: 16px;
    margin-bottom: 30px;
  }

  &__link {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    text-transform: uppercase;
    color: $primary;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  @media (max-width: 1599px) {
    &__title {
      font-size: 30px;
      margin-bottom: 20px;
    }

    &__desc {
      font-size: 14px;
      margin-bottom: 20px;
    }

    &__link {
      font-size: 16px;
    }
  }

  @media (max-width: 991px) {
    background-color: $light;
    padding: 30px 0;

    &__title {
      font-size: 24px;
      text-align: center;
    }

    &__desc {
      margin-bottom: 20px;
    }

    &__content {
      position: static;
      transform: unset;
      padding: 0 0 20px;
    }

    &__inner {
      width: 100%;
    }
  }

  // @media (min-width: 1200px) {
  //   &.hide-content &__inner {
  //     opacity: 0;
  //   }
  // }
}
