@mixin slider-nav-1 {
  &__prev,
  &__next {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #000;
    background-color: #fff;
    font-size: 18px;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 3%;
    transform: translateY(-50%);
    cursor: pointer;
    @include shadow("");
    transition: 0.3s ease-out;

    &:hover {
      @include shadow("");
      transform: translateY(-50%) scale(1.2);
    }

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 3%;
  }

  &:hover &__prev,
  &:hover &__next {
    background-color: rgba(#fff, 1);
  }

  @media (min-width: 1200px) {
    &__prev {
      left: 0;
      opacity: 0;
    }

    &__next {
      right: 0;
      opacity: 0;
    }

    &:hover &__prev {
      left: 3%;
      opacity: 1;
    }

    &:hover &__next {
      right: 3%;
      opacity: 1;
    }
  }

  @media (max-width: 575px) {
    &__prev,
    &__next {
      background-color: rgba(#fff, 0.5);
    }
  }
}

@mixin slider-nav-2 {
  &__prev,
  &__next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid $border-color;
    z-index: 10;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    transition: 0.3s ease-out;

    &:hover {
      @include shadow("");
      transform: translate(-50%, -50%) scale(1.2);
    }

    &:focus {
      outline: 0;
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  &__next {
    left: 100%;
  }
}

.partner-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    padding: 10px 5px;
    font-size: 18px;
    color: $secondary;
    font-size: 24px;
    color: $primary;
    position: absolute;
    top: 50%;
    left: -40px;
    z-index: 10;
    transform: translateY(-50%);
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: -40px;
  }

  &__frame {
    display: block;
    position: relative;
    // border: 1px solid $border-color;
    background-color: $white;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 60%;
    }

    img {
      width: 85%;
      height: 85%;
      object-fit: contain;
      object-position: 50% 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .swiper-wrapper {
    transition-timing-function: linear;
  }

  .swiper-slide {
    // transform: scale(0.7);
    // transition: 0.4s;
    transition-timing-function: linear;

    // &-active {
    //   transform: scale(1);
    // }

    // &-prev {
    //   transform: scale(0.85) translateX(-8%);
    // }

    // &-next {
    //   transform: scale(0.85) translateX(8%);
    // }
  }

  @media (max-width: 575px) {
    &__prev,
    &__next {
      display: flex;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 0;
      justify-content: center;
      align-items: center;
      background-color: $white;
      border: 1px solid $border-color;
      font-size: 20px;
      line-height: 1;
    }

    &__prev {
      left: -15px;
    }

    &__next {
      right: -15px;
    }
  }
}

.product-slider {
  max-width: 1500px;
  margin: 0 auto;

  .swiper-slide {
    padding: 10px;
    height: auto;
  }
}

.banner-slider {
  position: relative;
  z-index: 1;

  &__prev,
  &__next {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    color: $white;
    position: absolute;
    top: 50%;
    left: 3%;
    z-index: 10;
    transform: translateY(-50%);
    cursor: pointer;
    background-color: rgba($primary, 0.6);

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 3%;
  }

  .swiper-slide {
    height: auto;
  }

  &__frame {
    display: block;
    position: relative;
    z-index: 1;

    img {
      display: block;
      width: 100%;
      min-height: 200px;
      object-fit: cover;
      object-position: 50% 50%;
    }

    // &::after {
    //   content: "";
    //   display: block;
    //   width: 100%;
    //   height: 100%;
    //   background: linear-gradient(to right, #5099d5, transparent);
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: 10;
    // }
  }

  &__item {
    position: relative;
    z-index: 1;
  }

  &__content {
    width: 100%;
    padding: 0 calc(3% + 50px);
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &__title {
    font-size: 50px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    line-height: 1.2;
    margin-bottom: 16px;
    transform: translateY(150px);
    transition: 0.4s 1s ease-out;
    opacity: 0;
  }

  &__desc {
    font-size: 24px;
    font-weight: 700;
    color: $white;
    max-width: 510px;
    transform: translateY(150px);
    transition: 0.4s 1s ease-out;
    opacity: 0;
  }

  .swiper-slide-active &__title {
    opacity: 1;
    transform: translateY(0);
    transition: 0.8s 0.8s ease-out;
  }

  .swiper-slide-active &__desc {
    opacity: 1;
    transform: translateY(0);
    transition: 0.8s 1.4s ease-out;
  }

  @media (max-width: 1799px) {
    &__title {
      font-size: 40px;
    }

    &__desc {
      font-size: 20px;
    }
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 24px;
      margin-bottom: 10px;
    }

    &__desc {
      font-size: 16px;
    }

    &__prev,
    &__next {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 575px) {
    &__content {
      padding: 0 40px;
    }

    &__title {
      font-size: 20px;
    }

    &__desc {
      font-size: 12px;
    }
  }
}
