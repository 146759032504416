$gutters: 4 8 10 16 20 30 40 50 60 80 100;

.gutter {
  @each $gutter in $gutters {
    &.-all-#{$gutter} {
      margin: 0 -#{$gutter / 2}px;
      & > [class^="col"] {
        padding: 0 #{$gutter / 2}px;
      }
    }
  }

  @each $gutter in $gutters {
    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-up($breakpoint) {
        &.-over-#{$breakpoint}-#{$gutter} {
          margin: 0 -#{$gutter / 2}px;
          & > [class^="col"] {
            padding: 0 #{$gutter / 2}px;
          }
        }
      }

      @include media-breakpoint-down($breakpoint) {
        &.-under-#{$breakpoint}-#{$gutter} {
          margin: 0 -#{$gutter / 2}px;
          & > [class^="col"] {
            padding: 0 #{$gutter / 2}px;
          }
        }
      }
    }
  }
}
