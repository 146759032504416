.section {
  padding: 30px 0;

  &__title {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 16px;
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 24px;
    }
  }
}

.section-2 {
  &__title {
    font-size: 24px;
    font-weight: 700;
    position: relative;
    padding-bottom: 8px;
    margin-bottom: 16px;

    &::after {
      content: "";
      display: block;
      width: 60px;
      height: 3px;
      background-color: $primary;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 20px;
    }
  }
}

.section-3 {
  padding: 30px 0;

  &__title {
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: $primary;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 30px;

    &::before {
      content: "";
      display: block;
      width: 100px;
      border-bottom: 1px solid #ccc;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      content: "";
      display: block;
      width: 25px;
      height: 5px;
      border-radius: 6px;
      background-color: $primary;
      position: absolute;
      bottom: -2px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (max-width: 1599px) {
    &__title {
      font-size: 30px;
    }
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 24px;
    }
  }
}
