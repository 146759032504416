.preview {
  margin-bottom: 20px;

  &__thumbs {
    margin-top: 10px;
  }

  @media (min-width: 1200px) {
    &__thumbs {
      margin-top: 20px;
    }
  }
}

.preview-slider {
  &__frame {
    @include frame("rect", 56.26%);
    background-color: $white;
  }
}

.thumb-slider {
  @include slider-nav-2;
  position: relative;
  max-width: 930px;
  margin: 0 auto;

  &__frame {
    @include frame("rect", 56.26%);
  }
}
