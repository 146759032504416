.pd-grid {
  display: grid;
  grid-template-columns: 4fr 4fr 3fr;
  grid-gap: 30px;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}

.pd-info {
  &__title {
    font-size: 36px;
    font-weight: 700;
    color: $danger;
    line-height: 1.2;
    margin-bottom: 16px;
  }

  &__label {
    display: inline-block;
    font-size: 14px;
    padding: 7px 15px;
    background-color: $primary;
    position: relative;
    color: $white;
    margin-bottom: 24px;

    &::after {
      content: "";
      display: block;
      border: 5px solid transparent;
      border-color: #6b000b #6b000b transparent transparent;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }

  @media (max-width: 1199px) {
    &__title {
      font-size: 24px;
    }
  }
}

.as-menu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  &__item {
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  &__link {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    color: #333;

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    img {
      margin-right: 8px;
    }
  }
}

.detail-tabs {
  border-bottom: 1px solid $border-color;
  margin-bottom: 24px;

  .nav-item {
    display: flex;

    &:not(:last-child) {
      margin-right: 60px;
    }
  }

  .nav-link {
    font-size: 16px;
    font-weight: 700;
    color: $primary;
    position: relative;
    padding: 0 0 8px;

    &.active::after {
      content: "";
      display: block;
      width: 100%;
      height: 3px;
      background-color: $primary;
      position: absolute;
      bottom: -2px;
      left: 0;
    }
  }
}
